'use client'

import { createContext } from 'react'

import { toast } from '@/components/ui/use-toast'
import { queryClient } from '@/services/query-client'
import {
  getSession,
  signIn as nextSignIn,
  signOut as nextSignOut,
  useSession,
} from 'next-auth/react'
import { useQuery } from 'react-query'
import { User, showUser } from '../services'

export interface RegisterUserResponse {
  user: User
  token: string
}

export type SignInCredentials = {
  email: string
  password: string
}
export type SignOutCredentials = {
  email?: string | null
}

export interface SignInResponse {
  user: User
  token: string
}

type UpdateUserData = Omit<Partial<User>, 'id'>

type SignInData = {
  provider: 'credentials'
  email: string
  password: string
  callbackUrl?: string | undefined | null
  redirect?: boolean | undefined | null
}

type Success = true | false

type AuthContextData = {
  hasRole(role: string | string[]): boolean
  signOut(): void
  updateUser: (data: UpdateUserData) => void
  signIn: (data: SignInData) => Promise<Success>
  isAuthenticated: boolean
  isLoading: boolean
  isAdmin: boolean
  user?: User | null
}

type AuthProviderProps = {
  children: React.ReactNode
}

export const AuthContext = createContext({} as AuthContextData)

export function AuthProvider({ children }: AuthProviderProps) {
  const session = useSession()

  const queryUser = useQuery<User>('user', showUser, {
    staleTime: 60000 * 60,
    onError: () => {
      toast({
        title: 'Não autorizado',
        variant: 'destructive',
      })

      nextSignOut()
    },
    retry: false,
    enabled: session.status === 'authenticated',
  })

  const user = queryUser.data

  const isAuthenticated = session.status === 'authenticated'
  const isLoading = queryUser.isLoading || queryUser.isFetching
  const isAdmin = !!(user && user.role === 'admin')

  function signOut() {
    nextSignOut()
  }

  async function signIn({
    email,
    password,
    provider,
    callbackUrl,
    redirect,
  }: SignInData): Promise<Success> {
    if (callbackUrl === null) {
      callbackUrl = undefined
    }
    if (redirect === null || redirect === undefined) {
      redirect = false
    }

    await nextSignIn(provider, {
      email,
      password,
      callbackUrl,
      redirect,
    })
    const session = await getSession()

    return !!(session && session.user)
  }

  function updateUser(data: UpdateUserData) {
    const newData = Object.entries(data).reduce((previous, [key, value]) => {
      if (value === undefined) {
        return previous
      }

      return {
        ...previous,
        [key]: value,
      }
    }, {} as User)

    queryClient.setQueryData<User>('user', (user) => {
      return {
        ...user!,
        ...newData,
      } as User
    })
  }

  function hasRole(role: string | string[]) {
    if (!user) {
      return false
    }

    if (Array.isArray(role)) {
      const roles = role

      return roles.some((someRole) => someRole === user.role)
    }

    return user.role === role
  }

  return (
    <>
      <AuthContext.Provider
        value={{
          user,
          isAdmin,
          isLoading,
          isAuthenticated,
          hasRole,
          updateUser,
          signIn,
          signOut,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  )
}
