import {
  defineStyleConfig,
  extendTheme,
  type ThemeConfig,
} from '@chakra-ui/react'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const Popover = defineStyleConfig({
  baseStyle: {
    bg: '#fff',
  },
})

const themeProps = {
  components: {
    Popover,
  },
  config,
  colors: {
    white: {
      '900': '#ffffff',
      '800': '#f3f3f3',
    },
    blackAlpha: {
      '500': '#4d4d4d',
    },
    blue: {
      '100': '#e8f5fd',
    },
    green: {
      '700': '#30f599',
    },
    gray: {
      '300': '#9a9ea3',
    },
  },
  fonts: {
    heading: 'Roboto, sans-serif',
    body: 'Roboto, sans-serif',
  },
  styles: {
    global: {
      '.react-multi-carousel-list': {
        position: 'relative',
      },
      // '.react-multi-carousel-item': {
      //   width: 'unset'
      // },
      '.react-multi-carousel-track': {
        p: '4',
        listStyle: 'none',
      },
      // html: {
      //   height: '100%',
      //   overflowY: 'none',
      // },

      // body: {
      //   minW: '690px',
      //   overflowY: 'none',
      //   height: '100%',
      //   bg: 'white.800',
      // },
      a: {
        color: 'inherit',
        textDecoration: 'none',
        letterSpacing: 'wider',
      },
      button: {
        color: 'inherit',
      },
      '*': {
        margin: '0',
        padding: '0',
        boxSizing: 'border-box',

        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'green.700',
          borderRadius: '10px',
          transition: 'all 0.2s',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: 'green.700',
        },
      },
    },
  },
}

export const theme = extendTheme(themeProps)
